<template>
  <div class="scanQr text-center">
    <img class="my-5" height="120" alt="logo" src="@/assets/logo_2022.png" />
    <form id="enterShopForm" novalidate>
      <div class="px-3">
        <div class="title">กรุณาใส่รหัสร้านค้า</div>
        <input
          type="tel"
          class="form-control mt-2"
          v-model="shopId"
          name="shopId"
          id="shopId"
          pattern="[0-9]{0,}"
          maxlength="20"
          placeholder="Shop ID"
          required
        />
        <div class="invalid-feedback">กรุณาใส่รหัสร้านให้ถูกต้อง</div>
        <p class="mt-3">* ค้นหาจากใบเสร็จรับเงิน บริษัทเสริมสุข</p>
        <div v-if="pdpa!=null">
          <div
            class="custom-control custom-checkbox mb-2 text-left d-flex"
            v-for="(item, index) in pdpa.subConsent"
            :key="item.subConsentId"
          >
            <input
              ref="check"
              type="checkbox"
              class="custom-control-input"
              :id="item.subConsentId"
              :name="item.subConsentId"
              v-model="checked_pdpa[index]"
              required
            />
            <label class="custom-control-label" :for="item.subConsentId">
              <span class="sr-only"></span>
            </label>
            <div>
              <div v-html="parseLink(item.description)" style="display:inline">  
              </div>
              <span v-if="index==0" class="click-text" @click="openModal(item.detail)"
                >คลิกที่นี่</span
              >
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-primary mt-5 w-100"
          data-tag="ok"
          :disabled="invalid"
        >
          ยืนยัน
        </button>
      </div>
    </form>
    <b-modal id="consent" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline" />
      </template>
      <div class="d-block text-center" v-if="pdpa">
        <div id="content" v-html="pdpa && pdpa.content && pdpa.content.replaceAll('101%','100%')" @scroll="handleScroll()">
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import config from "../../../config";

export default {
  name: "scanQr",
  data() {
    return {
      shopId: "",
      checked_pdpa: [],
      pdpa:null,
      checkList: [
        {
          id: "termCkb",
          title: "ข้าพเจ้าได้อ่านและรับทราบเงื่อนไขและข้อตกลงในการใช้บริการ",
          detail: {
            title: "Title",
            content: "Content",
          },
          check: false,
        },
        {
          id: "policyCkb",
          title: "ข้าพเจ้าได้อ่านและรับทราบเงื่อนไขและข้อตกลงในการใช้บริการ",
          detail: {
            title: "Title",
            content: "Content",
          },
          check: false,
        },
      ],
      consentDetail: null,
      scrollEnd: false,
    };
  },
  computed: {
    config() {
      return config;
    },
    invalid() {
      if (
        this.shopId !== "" &&
        (this.pdpa?.subConsent == null || this.pdpa?.subConsent.every((item,i) => this.checked_pdpa[i] === true ))
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    parseLink(html){
      return html.replaceAll("href=\" \"","")
    },
    openModal() {
      this.$bvModal.show("consent");
    },
    handleScroll() {
      const content = document.getElementById("content");

      if (
        content.scrollTop + content.clientHeight >=
        content.scrollHeight - 100
      ) {
        this.scrollEnd = true;
      }
    },
    initValidForm() {
      const form = document.getElementById("enterShopForm");
      // Loop over them and prevent submission
      form.addEventListener(
        "submit",
        (event) => {
          event.preventDefault();
          event.stopPropagation();
          if (form.checkValidity() !== false) {
            // when form validated
            this.checkShop(this.shopId);
          }
          form.classList.add("was-validated");
        },
        false
      );
    },
    // check shop ID
    checkShop(shopId) {
      window.loading(true);
      // get shop info
      fetch(process.env.VUE_APP_API_URL + "/shops/" + shopId, {
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      })
        .then((res) => {
          console.log("checkShop status:", res.status);
          window.loading(false);

          // check status for redirect some page or set params
          if (res.status == 200) {
            this.$router.replace("/user/success");
            return;
          } else if (res.status == 403) {
            this.$store.dispatch("setStoreID", shopId);
            this.$router.replace({
              name: "auth",
              params: { shopid: shopId, existMember: true },
            });
            return;
          }
          if (res.status == 204) {
            this.$store.dispatch("setStoreID", shopId);
            this.$router.replace({
              name: "auth",
              params: { shopid: shopId, existMember: false },
            });
            return;
          }

          // if in case give error
          console.log("status no case", res);
          this.$Error();
        })
        .catch((error) => {
          console.log("fetch error:", error);
          this.$Error();
        });
    },
    async fetchAuth() {
      // get shop info
      return await fetch(`${process.env.VUE_APP_API_URL}/auth`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async checkAuth() {
      window.loading(true);
      try {
        const res = await this.fetchAuth();
        console.log("fetchAuth status:", res.status);
        switch (res.status) {
          case 200: {
            this.$router.replace("/user/success");
            const body = (await res.json()) || {};
            console.log("fetchAuth body:", body);
            return;
          }
          case 401:
            console.log("case 401");
            break;
          default: {
            console.log("no case");
            throw "no case";
          }
        }
      } catch (error) {
        console.log(error);
        window.loading(false);
        this.Swal.fire(
          "ขออภัยเกิดข้อผิดพลาด",
          "กรุณาลองใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่",
          "error"
        ).then(() => {
          window.liff.closeWindow();
        });
      }
      window.loading(false);
    },
    async fetchPDPA() {
      window.loading(true);
      try{
        const res = await fetch(process.env.VUE_APP_API_URL + "/consents/pdpa", {
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        })
        let data =[]
        switch (res.status) {
          case 200:
            data = await res.json()
            this.pdpa = data[0]
            break;
          default:
            throw ("no case", res);
        }
      }catch(error){
        console.error("fetchPDPA error:", error);
        this.$Error();
      }finally{
        window.loading(false);
      }
    },
    async handleOK(data) {
      switch (data.code) {
        case "LOGIN":
          this.login();
          break;
        case "REGISTER":
          this.goSetPin();
          // this.consentModal.show();
          break;
        default:
          window.$error();
          break;
      }
    },
  },
  async mounted() {
    this.initValidForm();
    await this.checkAuth(); // check this user already shop?
    await this.fetchPDPA();
  },
};
</script>

<style scoped>
.title {
  font-size: 24px;
  color: #2599d6;
}
input#shopId {
  letter-spacing: 5px;
  font-size: 20px;
  text-align: center;
}
.click-text {
  cursor: pointer;
  text-decoration: underline;
  z-index: 1;
}
#consent {
  overflow: scroll;
  max-height: 400px;
  line-height: 1.1em;
  font-size: 0.9em;
}
</style>
