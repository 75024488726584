<template>
  <div class="otp">
    <div class="container">
      <div v-if="route_back" class="mt-3" @click="$router.replace(route_back)">
        <i class="icon-left-open-big"></i>กลับ
      </div>
      <div :class="'title mb-4 text-center ' + (route_back ? 'mt-3' : 'mt-5')">
        ยืนยันเบอร์มือถือ
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <form
            id="requestSmsForm"
            action
            class="px-3 needs-validation"
            novalidate
          >
            <!-- tel -->
            <label for="tel">เบอร์โทรศัพท์มือถือ</label>
            <div class="row">
              <div class="col-8 pr-1">
                <div class="form-group">
                  <input
                    type="tel"
                    id="tel"
                    name="phoneNumber"
                    class="form-control"
                    placeholder="0999999999"
                    pattern="[0]{1}[0-9]{9}"
                    maxlength="10"
                    required
                    v-model="phone"
                  />
                  <div class="refOtp pt-0" v-if="refOtp">Ref: {{ refOtp }}</div>
                  <div class="invalid-feedback">ต้องเป็นตัวเลข 10 หลัก</div>
                </div>
              </div>
              <div class="col-4 pl-1">
                <button
                  class="btn btn-outline-primary w-100"
                  type="submit"
                  id="send_otp"
                  :disabled="statusOtp != 'none'"
                  data-tag="send_otp"
                >
                  <span v-if="statusOtp == 'none'">ส่ง OTP</span>
                  <span v-if="statusOtp == 'wait'" class="wait"></span>
                  <span v-if="statusOtp == 'timeout'" style="color: black"
                    >{{ timeout }}s</span
                  >
                </button>
              </div>
            </div>
          </form>
          <form
            v-if="sentOtp"
            id="enterOtpForm"
            action
            class="px-3 needs-validation"
            novalidate
          >
            <!-- otp code input-->
            <div class="form-group">
              <label for="otpCode">รหัส OTP</label>
              <input
                type="tel"
                id="otpCodeInput"
                name="otp"
                class="form-control"
                pattern="[0-9]{6}"
                maxlength="6"
                required
                placeholder="123456"
              />
              <div class="invalid-feedback">OTP ควรใช้ตัวเลข 6 หลัก</div>
            </div>

            <!-- process button -->
            <button
              id="processOkBtn"
              type="submit"
              style="width: 100%"
              class="btn btn-primary mt-5"
              data-tag="ok"
            >
              ต่อไป
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'otp',
  props: {
    route_back: {
      type: Object,
      default: null,
    },
    route_after_success: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      statusOtp: 'none',
      timeout: 0,
      setTimeout: null,
      sentOtp: false,
      clickOtp: false,
      refOtp: '',
      phone: null,
      otpValidAddlis: false,
    }
  },
  components: {},
  methods: {
    // init validate form
    initValidRequestSmsForm() {
      const form = document.getElementById('requestSmsForm')
      // Loop over them and prevent submission
      form.addEventListener(
        'submit',
        (e) => {
          e.preventDefault()
          e.stopPropagation()

          if (form.checkValidity() !== false) {
            // when form validated
            this.sendOtpSms(form)
          }
          form.classList.add('was-validated')
        },
        false
      )
    },
    initValidEnterOtpForm() {
      const form = document.getElementById('enterOtpForm')
      // Loop over them and prevent submission
      form.addEventListener(
        'submit',
        (e) => {
          e.preventDefault()
          e.stopPropagation()

          if (form.checkValidity() !== false) {
            // when form validated
            this.postOtpValidate(form)
          }
          form.classList.add('was-validated')
        },
        false
      )
    },
    sendSmsAgain() {},
    // set timeout click request sms otp
    async setTimeoutOtp() {
      this.statusOtp = 'timeout'
      this.timeout = 60
      this.setTimeout = setInterval(() => {
        this.timeout -= 1
      }, 1000)
    },
    async postSendOtpSms() {
      // const formData = new FormData(form);
      // formData.append("serviceName", "Register");
      // formData.append("shopCode", this.$store.state.storeId);

      // this.$LogFormData("postSendOtpSms data:", formData);
      // get shop info

      const reqBody = {
        phoneNumber: document.getElementById('tel').value,
        serviceName: 'Register',
        // shopCode: this.$store.state.storeId,
      }

      const headers = new Headers({
        Authorization: `Bearer ${this.$JWT}`,
        'Content-Type': 'application/json',
      })

      return await fetch(process.env.VUE_APP_API_URL + '/otp', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(reqBody),
      })
    },
    async sendOtpSms(form) {
      this.statusOtp = 'wait'
      try {
        const res = await this.postSendOtpSms(form)
        console.log('postSendOtpSms status:', res.status)
        // check status for redirect some page or set params
        let body = []
        switch (res.status) {
          case 201:
            this.sentOtp = true
            this.$nextTick(() => {
              // check has been addEventListener before ?
              if (!this.otpValidAddlis) {
                this.initValidEnterOtpForm()
                this.otpValidAddlis = true
              }
            })
            body = await res.json()
            this.refOtp = body.otpRefCode
            //form.classList.remove("was-validated");
            this.setTimeoutOtp()
            break
          case 422:
            this.statusOtp = 'none'
            body = await res.json()
            // if in case give error
            this.alertError(body.code)
            break
          case 429:
            this.statusOtp = 'none'
            body = await res.json()
            if (body?.lockStatus === 'lock') {
              const thaiDatetime = this.$convertToThaiDatetime(body?.lockUntil)

              this.Swal.fire(
                `กรุณาขอ OTP ใหม่ภายหลัง`,
                `${thaiDatetime}`,
                'warning'
              )
            } else {
              // if in case give error
              this.alertError(body)
            }
            break
          default:
            this.statusOtp = 'none'
            console.log('no case:')
            this.alertError()
        }
      } catch (error) {
        console.log(error)
        window.$pushEvent('otp', 'error', error)
        this.statusOtp = 'none'
        window.loading(false)
        this.alertError()
      } finally {
        window.loading(false)
      }
    },
    alertError(code) {
      switch (code) {
        case 'INVALID_PHONENUMBER':
          this.Swal.fire(
            'เบอร์โทรศัพท์ไม่ถูกต้อง',
            'กรุณาลองใหม่ มีข้อสงสัยกรุณาติดต่อเจ้าหน้าที่',
            'error'
          )
          break
        case 'PHONENUMBER_MISS_MATCH':
          this.Swal.fire(
            'เบอร์โทรศัพท์ไม่ตรงกับระบบ',
            'กรุณาลองใหม่ มีข้อสงสัยกรุณาติดต่อเจ้าหน้าที่',
            'error'
          )
          break
        default:
          // Too Many Requests OTP
          // Rate limit exceeded
          this.Swal.fire('กรุณาส่งรหัส OTP อีกครั้ง', '', 'warning')
      }
    },
    async postOtpValidate(form) {
      window.loading(true)

      const formData = new FormData(form)
      formData.append('otpRefCode', this.refOtp)
      // formData.append("shopCode", this.$store.state.storeId);

      // this.$LogFormData("postOtpValidate data:", formData);

      // send otp sms
      return await fetch(process.env.VUE_APP_API_URL + '/otp/validate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
        body: formData,
      })
        .then((res) => {
          console.log('postOtpValidate status', res.status)
          window.loading(false)

          if (res.status == 200) {
            console.log('otp success')

            this.$store.dispatch('setOtpData', {
              otpRef: this.refOtp,
              phoneNumber: this.phone,
            })

            // route after success
            this.$router.replace({
              ...this.route_after_success,
              params: {
                ...this.route_after_success.params,
                phone: this.phone,
                refCode: this.refOtp,
              },
            })
            return
          }

          if (res.status == 422) {
            this.Swal.fire(
              'ขออภัย',
              'รหัส OTP หรือข้อมูลไม่ถูกต้อง กรุณาลองใหม่ภายหลัง',
              'warning'
            )
            return
          }

          // if in case give error
          console.log('status no case', res)
          this.$Error()
        })
        .catch((error) => {
          console.log(error)
          window.loading(false)
          this.$Error()
        })
    },
  },
  watch: {
    // change stage when timeout
    timeout(val) {
      if (val <= 0) {
        clearInterval(this.setTimeout)
        this.statusOtp = 'none'
      }
    },
  },
  async mounted() {
    if (!this.route_after_success) {
      this.Swal.fire('ขออภัย', 'การเข้าถึงไม่ถูกต้อง', 'error')
    }

    console.log('route_back:', this.route_back)
    console.log('route_after_success:', this.route_after_success)

    // init validate form after html display success
    if (!this.$readOnly) this.initValidRequestSmsForm()
  },
}
</script>

<style scoped>
.otp {
  color: #2599d6;
}

.title {
  font-size: 30px;
}

#pinInput {
  letter-spacing: 0.9em;
  text-align: center;
  text-indent: 0.9em;
  font-size: 18px;
  -webkit-text-security: disc;
  -webkit-text-stroke-width: 0.2em;
}

.form-control {
  text-align: center;
  letter-spacing: 0.2rem;
}

#otpCodeInput {
  letter-spacing: 0.8rem;
}

.wait:after {
  text-indent: 10em;
  margin-right: 10px;
  content: '\2022';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(255, 255, 255, 0);
    text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
      0.5em 0 0 rgba(255, 255, 255, 0);
  }

  40% {
    color: rgb(0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
      0.5em 0 0 rgba(255, 255, 255, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgba(255, 255, 255, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgb(0, 0, 0);
  }
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #e8e8e8;
  background-color: #c1c1c1;
  border-color: #c3c3c3;
}

.refOtp {
  color: cadetblue;
}
</style>
