<template>
  <div class="setpin root">
    <div class="container">
      <div class="title mt-5 mb-4 text-center">ตั้งรหัส PIN</div>
      <div class="row justify-content-center">
        <div class="col-12">
          <form id="form" action class="p-3 needs-validation" novalidate>
            <!-- set pin -->

            <div class="form-group">
              <label for="tel">กำหนด PIN</label>
              <input
                type="tel"
                id="pin"
                name="pincode"
                class="form-control"
                placeholder="123456"
                pattern="[0-9]{6}"
                maxlength="6"
                required
                v-model="pin_model"
              />
              <div class="invalid-feedback">กรุณาใส่ตัวเลข 6 หลัก</div>
            </div>

            <!-- confirm pin -->
            <div class="form-group">
              <label for="tel">ยืนยัน PIN</label>
              <input
                type="tel"
                id="confirm_pin"
                class="form-control"
                placeholder
                maxlength="6"
                v-on:input="validConfirmPass($event, 'pin')"
              />
              <div class="invalid-feedback">กรุณาใส่ PIN ให้ตรงกัน</div>
              <!-- <div class v-if="confirm_error">กรุณาใส่ PIN ให้ตรงกัน</div> -->
            </div>
            <div class="mt-5">
              <button class="btn btn-primary w-100" data-tag="confirm">
                ยืนยัน
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "setpin",
  props: { phone: {}, refCode: {}, route_after_success: {} },
  data() {
    return {
      statusOtp: "none",
      timeout: 0,
      setTimeout: null,
      sentOtp: false,
      clickOtp: true,
      refOtp: null,
      confirm_error: false,
      pin_model: "",
    };
  },
  components: {},
  methods: {
    // init validate form
    initValidForm() {
      const form = document.getElementById("form");
      const pin = document.getElementById("pin");
      const confirm_pin = document.getElementById("confirm_pin");
      // Loop over them and prevent submission
      form.addEventListener(
        "submit",
        (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (confirm_pin.value == pin.value && pin.value) {
            confirm_pin.setCustomValidity("");
          } else {
            confirm_pin.setCustomValidity("invalid");
          }

          if (form.checkValidity() !== false) {
            this.sumbitSetPin(form);
          }
          form.classList.add("was-validated");
        },
        false
      );
    },
    validConfirmPass(e, s) {
      const set = document.getElementById(s);
      e.target.setCustomValidity(set.value == e.target.value ? "" : "invalid");
    },
    async patchSetpin() {
      const formData = new FormData();
      formData.append("pincode", this.pin_model);
      formData.append("phoneNumber", this.phone);
      formData.append("otpRef", this.refCode);
      // formData.append("shopCode", this.$store.state.storeId);

      // this.$LogFormData("patchSetpin data:", formData);

      // get shop info
      return await fetch(process.env.VUE_APP_API_URL + "/me", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
        body: formData,
      });
    },
    async sumbitSetPin(form) {
      window.loading(true);
      try {
        const res = await this.patchSetpin(form);

        console.log("patchSetpin status:", res.status);
        // check status for redirect some page or set params
        if (res.status == 200) {
          console.log("route_after_success : ", this.route_after_success);
          if (this.route_after_success) {
            this.Swal.fire("Reset Pin สำเร็จ", "", "success").then(() => {
              this.$router.replace(this.route_after_success);
            });
          } else {
            const body = (await res.json()) || [];
            console.log("patchSetpin body:", body);
            window.loading(false);
            this.$router
              .replace({
                name: "update-success",
              })
              .catch(() => {});
          }
        } else {
          throw "no case status";
        }
      } catch (error) {
        this.$Error();
      }
      window.loading(false);
    },
  },

  async mounted() {
    // init validate form after html display success
    this.initValidForm();
    window.loading(false);
  },
};
</script>

<style lang="scss" scoped>
.root {
  color: #2599d6;
}

.title {
  font-size: 30px;
}

.form-control {
  text-align: center;
  letter-spacing: 0.8rem;
}
</style>
