<template>
  <div></div>
</template>

<script>
export default {
  props: { phone: {}, refCode: {} },
  methods: {
    postResgisUser() {
      window.loading(true);
      console.log("in postResgisUser");

      let formData = new FormData();
      formData.append("citizenID", this.$store.state.registerData.citizenID);
      formData.append("shopCode", this.$store.state.registerData.shopCode);
      formData.append("otpRef", this.$store.state.otp.otpRef);
      formData.append("phoneNumber", this.$store.state.otp.phoneNumber);

      // get shop info
      fetch(process.env.VUE_APP_API_URL + "/users", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
        body: formData,
      }).then(async (res) => {
        console.log("status:", res.status);
        window.loading(false);

        // check status for some action
        if (res.status == 200) {
          this.$router.replace("/user/success");
          return;
        }

        if (res.status == 201) {
          this.$store.dispatch(
            "setStoreID",
            this.$store.state.registerData.shopCode
          );
          window.loading(true);
          this.$router.replace({
            name: "set-pin",
            params: {
              phone: this.$store.state.otp.phoneNumber,
              refCode: this.$store.state.otp.otpRef,
            },
          });
          return;
        }

        if (res.status == 412) {
          const resBody = await res.json();
          if (resBody == "Duplicated Phonenumber") {
            this.Swal.fire("ขออภัย", "เบอร์โทรศัพท์ซ้ำในระบบ", "warning").then(
              () => {
                this.$router.replace("/user/enter-shop");
              }
            );
            return;
          }
        }

        if (res.status == 422) {
          this.Swal.fire(
            "ขออภัย",
            "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลหรือเข้าใช้งานใหม่ภายหลัง",
            "warning"
          );
          return;
        }

        // if in case give error
        console.log("status no case");
        this.$Error();
      });
    },
  },
  mounted() {
    console.log(this.$store.state);
    this.postResgisUser();
  },
};
</script>
