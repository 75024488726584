<template>
  <div class="auth">
    <div class="container">
      <div class="title mt-4 mb-2 text-center">ยืนยันตัวตน</div>
      <div class="row justify-content-center">
        <div class="col-sm-10 col-12 px-0">
          <form id="authForm" action class="p-3 needs-validation" novalidate>
            <!-- shop code -->
            <div class="form-group">
              <label for="shop_id">รหัสร้านค้า</label>
              <input
                type="text"
                id="shop_code"
                name="shopCode"
                class="form-control"
                :value="shopid"
                readonly
                required
              />
            </div>

            <!-- citizen id input-->
            <div class="form-group">
              <label for="pidInput">รหัสบัตรประชาชน</label>
              <input
                type="text"
                id="pidInput"
                name="citizenID"
                class="form-control"
                maxlength="13"
                required
              />
              <div class="invalid-feedback">กรุณาใส่ข้อมูล</div>
            </div>

            <!-- pincode input -->
            <div v-if="existMember" class="row justify-content-center">
              <div class="col-11">
                <div class="form-group text-center">
                  <label for="pinInput">PINCODE</label>
                  <input
                    type="tel"
                    id="pinInput"
                    pattern="[0-9]{6}"
                    maxlength="6"
                    name="pincode"
                    inputmode="numeric"
                    class="mx-auto form-control text-center"
                    required
                  />
                  <div class="invalid-feedback">ต้องเป็นตัวเลข 6 หลัก</div>
                </div>
              </div>
            </div>

            <!-- process button -->
            <button
              id="processOkBtn"
              type="submit"
              style="width: 100%"
              class="btn btn-primary my-2"
            >
              ต่อไป
            </button>

            <!-- forgot pin button -->
            <button
              type="button"
              id="resetPin"
              v-if="existMember"
              @click="resetPin()"
              class="btn btn-light"
              style="width: 100%"
            >
              ลืม Pincode ?
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'auth',
  props: ['existMember', 'shopid'],
  data() {
    return {}
  },
  components: {},
  methods: {
    // init validate form
    initValidForm() {
      const form = document.getElementById('authForm')
      // Loop over them and prevent submission
      form.addEventListener(
        'submit',
        (event) => {
          event.preventDefault()
          event.stopPropagation()
          if (form.checkValidity() !== false) {
            // when form validated
            if (this.existMember) {
              this.postLoginUser(form)
            } else {
              this.regisUser(form)
            }
          }
          form.classList.add('was-validated')
        },
        false
      )
    },
    resetPin() {
      this.$router.push({
        name: 'otp',
        params: {
          route_back: this.$route,
          route_after_success: {
            name: 'set-pin',
            params: {
              route_after_success: this.$route,
            },
          },
        },
      })
    },
    async regisUser(form) {
      // const result = await this.postResgisUser(form);
      const formData = new FormData(form)
      const shopCode = formData.get('shopCode')
      const citizenID = formData.get('citizenID')
      this.$store.dispatch('setRegisterData', { shopCode, citizenID })
      this.$router.replace({
        name: 'otp',
        params: { route_after_success: { name: 'processRegister' } },
      })
      // console.log("rs json:", result);
    },
    // wait api
    postLoginUser(form) {
      window.loading(true)
      console.log('in postLoginUser')

      let formData = new FormData(form)

      // get shop info
      fetch(process.env.VUE_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
        body: formData,
      })
        .then(async (res) => {
          console.log('status:', res.status)
          window.loading(false)

          // check status for redirect some page or set params
          if (res.status == 200) {
            this.$router.replace({ name: 'update-success' }).catch(() => {})
            return
          }

          if (res.status == 422) {
            this.Swal.fire(
              'ขออภัย',
              'ข้อมูลไม่ถูกต้อง กรุณาทำรายการใหม่ภายหลัง',
              'error'
            )
            return
          }

          if (res.status === 429) {
            const body = await res.json()
            if (body?.lockStatus === 'lock') {
              const thaiDatetime = this.$convertToThaiDatetime(body?.lockUntil)

              this.Swal.fire(
                `กรุณาเข้าสู่ระบบใหม่ ภายหลัง`,
                `${thaiDatetime}`,
                'warning'
              )
              return
            } else {
              // Rate limit exceeded
              // if in case give error
              console.log('status no case')
              this.$Error()
              return
            }
          }
          // if in case give error
          console.log('status no case')
          this.$Error()
          return
        })
        .catch((error) => {
          console.log(error)
          window.loading(false)
          this.$Error()
        })
    },
  },
  async mounted() {
    console.log('params:', this.$route.params)
    // init validate form after html display success
    if (!this.$readOnly) this.initValidForm()
  },
}
</script>

<style scoped>
.auth {
  color: #2599d6;
}

.title {
  font-size: 30px;
}

#pinInput {
  letter-spacing: 0.9em;
  text-align: center;
  font-size: 24px;
  -webkit-text-security: disc;
  -webkit-text-stroke-width: 0.2em;
}

.form-control {
  text-align: center;
  letter-spacing: 0.4rem;
}
</style>
