<template>
  <div class="success root">
    <div class="container text-center">
      <img class="my-5" alt="logo" src="@/assets/logo.png" />
      <div class="title mt-3 mb-4">บันทึกข้อมูลสำเร็จ</div>
      <div v-if="user_data.shopCode">
        <div>รหัสร้านค้า: {{ user_data.shopCode }}</div>
        <div>รหัสบัตรประชาชน: {{ user_data.citizenID }}</div>
        <div>เบอร์โทรศัพท์: {{ user_data.phoneNumber }}</div>
      </div>

      <button
        v-on:click="ok()"
        style="width: 80%"
        class="btn btn-primary position-absolute mx-auto mb-4 fixed-bottom"
        data-tag="ok"
      >
        ตกลง
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      user_data: {},
    };
  },
  methods: {
    ok() {
      window.liff.closeWindow();
    },
    async fetchData() {
      // get shop info
      return await fetch(`${process.env.VUE_APP_API_URL}/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async getData() {
      window.loading(true);
      try {
        const res = await this.fetchData();
        console.log("fetchMe status:", res.status);
        let body = [];
        try {
          body = await res.json();
          console.log("fetchMe body:", body);
        } catch (error) {
          console.log(error);
        }

        this.user_data = body || {};
      } catch (error) {
        console.log(error);
      }
      window.loading(false);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style  scoped>
.root {
  color: #2599d6;
}

.title {
  color: #2599d6;
  font-size: 30px;
}
</style>
